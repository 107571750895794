import { type BuilderViewFilterConnectionSources } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';

type ViewsWithRestrictedConnections = TableView | ListView | CalendarView | MapView;

function getActiveTableConnectionFields(columns: TableViewColumn[], fields: KnackField[]) {
  const activeFieldKeys = new Set(columns.map((column) => column.field?.key).filter(Boolean));

  return (fields.filter((field) => activeFieldKeys.has(field.key) && field.type === 'connection') ||
    []) as ConnectionField[];
}

export function getRestrictedConnectionFields(
  sourceObject: KnackObject,
  view: ViewsWithRestrictedConnections
) {
  const connectionFieldsInSourceObject = sourceObject.fields.filter((f) => f.type === 'connection');

  if (view.type === 'table') {
    const connectionFieldsActiveInView = getActiveTableConnectionFields(
      view.columns,
      connectionFieldsInSourceObject
    );

    const restrictedConnectionFields =
      view.filter_fields === 'object'
        ? connectionFieldsInSourceObject
        : connectionFieldsActiveInView;

    return restrictedConnectionFields;
  }

  // TODO: Implement other views FE-3856
  return [];
}

export const getDefaultFilterConnectionSources = (
  restrictedConnectionFields: ConnectionField[]
) => {
  let filterConnectionSources: BuilderViewFilterConnectionSources = {};
  const defaultConnectionSource = { source: { connection_key: '' }, filters: [] };

  restrictedConnectionFields.forEach((connectionField) => {
    filterConnectionSources = {
      ...filterConnectionSources,
      [connectionField.key]: defaultConnectionSource
    };
  });

  return filterConnectionSources;
};

export const getFilterConnectionSources = (
  restrictedConnectionFields: ConnectionField[],
  currentFilterConnectionSources: BuilderViewFilterConnectionSources | undefined
) => {
  let filterConnectionSources: BuilderViewFilterConnectionSources = {};
  const defaultConnectionSource = { source: { connection_key: undefined }, filters: [] };

  if (!currentFilterConnectionSources) {
    return undefined;
  }

  // If there are existing connection sources, generate connection sources from existing connection sources
  // without changing the existing ones but if there are less connection fields than connection sources, remove the extra connection sources
  restrictedConnectionFields.forEach((connectionField) => {
    const existingConnectionSource = currentFilterConnectionSources?.[connectionField.key];

    if (existingConnectionSource) {
      filterConnectionSources = {
        ...filterConnectionSources,
        [connectionField.key]: existingConnectionSource
      };
    } else {
      filterConnectionSources = {
        ...filterConnectionSources,
        [connectionField.key]: defaultConnectionSource
      };
    }
  });
  return filterConnectionSources;
};

export function formatRestrictedConnectionSourceGroup(
  key: KnackFieldKey,
  filterConnectionSources: BuilderViewFilterConnectionSources
) {
  return {
    key: key as KnackFieldKey,
    source: filterConnectionSources?.[key].source,
    filters: filterConnectionSources?.[key].filters
  };
}
