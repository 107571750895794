import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type TableViewActionRule } from '@/types/schema/views/TableView';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

export function ButtonTextInput() {
  const [t] = useTranslation();
  const {
    register,
    formState: { errors },
    clearErrors
  } = useFormContext<TableViewActionRule>();

  return (
    <div className="mt-4">
      <Label htmlFor="button-text">
        {t(
          'pages.element_settings.table.categories.data_display.field_management.dynamic_actions_column.button_text'
        )}
      </Label>
      <Input
        data-testid="dynamic-action-dialog-button-text"
        id="button-text"
        placeholder={t(
          'pages.element_settings.table.categories.data_display.field_management.dynamic_actions_column.enter_button_text_placeholder'
        )}
        intent={errors.link_text ? 'destructive' : 'default'}
        className="mt-1"
        {...register('link_text', {
          onChange: () => errors.link_text && clearErrors('link_text')
        })}
      />
      <FormErrorMessage name="link_text" className="mt-2" errors={errors} />
    </div>
  );
}
