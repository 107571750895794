import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { HiMiniViewColumns as FieldIcon } from 'react-icons/hi2';
import { generatePath, useNavigate } from 'react-router-dom';
import { Spinner, Tabs, useToast } from '@knack/asterisk-react';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { DataTableDisplay } from '@/components/data-table/display/DataTableDisplay';
import { DataTableHeader } from '@/components/data-table/display/header/DataTableHeader';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { FieldsHeader } from '@/pages/tables/fields/header/FieldsHeader';
import { TablesPageTopbar } from '@/pages/tables/TablesPageTopbar';
import { ROUTES } from '@/Router';
import { FieldsList } from './fields/FieldsList';
import { useFieldsStore } from './fields/useFieldsStore';

export type TablesPageTab = {
  value: 'records' | 'fields';
  children: React.ReactNode;
};

type TablesPageLayoutProps = {
  objectKey: KnackObject['key'];
  activeTab?: TablesPageTab['value'];
};

type DataTableDisplayInitializeProps = {
  objectKey: KnackObject['key'];
  children: React.ReactNode;
  app: BuilderApplication;
};

function DataTableDisplayInitialize({ objectKey, children, app }: DataTableDisplayInitializeProps) {
  const isInitialLoad = useDataTableStore().use.isInitialLoad();
  const { initialize: initializeDataTableStore } = useDataTableStore().use.actions();

  useEffect(() => {
    void initializeDataTableStore({ app, objectKey });
  }, [app, initializeDataTableStore, objectKey]);

  if (isInitialLoad) {
    return (
      <div
        data-testid="data-table-display-records"
        className="flex min-h-[300px] w-full items-center justify-center"
      >
        <Spinner />
      </div>
    );
  }
  return children;
}

export function TablesPageLayout({ objectKey, activeTab = 'records' }: TablesPageLayoutProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { presentToast } = useToast();
  const { data: app, isLoading: isAppLoading } = useApplicationQuery();
  const { initialize: initializeFieldsStore } = useFieldsStore().use.actions();
  const { reset, refetchPagesInViewport } = useDataTableStore().use.actions();
  const currentTable = useCurrentTable();
  const isUserObject = currentTable?.type === 'UserObject';
  const [updatedActiveTab, setUpdatedActiveTab] = useState<string>(activeTab);

  // If the DataTable has not been initialized, use the value from the application
  const recordCountFromApplication = app?.counts?.objects?.[objectKey] || 0;
  const dataTableStoreTotalRecords = useDataTableStore().use.totalRecords();
  const totalRecords = dataTableStoreTotalRecords || recordCountFromApplication;
  const chipClassname =
    'm-0 inline-flex h-5 min-w-5 items-center justify-center gap-1 truncate rounded-md bg-subtle p-1 text-xs font-semibold text-default hover:bg-emphasis hover:text-emphasis';

  const fields = useFieldsStore().use.fields();

  // We want to trigger a refresh when the Records Tab is clicked
  // Radix tab doesn't trigger a change event when the same tab is clicked
  // So we trigger a refresh when the current tab is "records" and we are not changing the tabs
  const isChangingTab = useRef(false);

  useEffect(() => {
    isChangingTab.current = false;
  }, [activeTab]);

  const items: TablesPageTab[] = [
    {
      children: (
        <span className="flex items-center gap-1">
          <DatabaseIcon
            size={20}
            className={cn('flex-shrink-0', {
              'fill-[url(#svg-gradient-1)]': activeTab === 'records'
            })}
          />
          {isUserObject
            ? t('components.data_table.header.users')
            : t('components.data_table.header.records')}
          <span className={chipClassname}>{String(totalRecords)}</span>
        </span>
      ),
      value: 'records'
    },
    {
      children: (
        <span className="flex items-center gap-1">
          <FieldIcon
            size={20}
            className={cn('flex-shrink-0', {
              'fill-[url(#svg-gradient-1)]': activeTab === 'fields'
            })}
          />
          <span>{t('components.data_table.header.fields')}</span>

          <span className={chipClassname}>{String(fields?.length)}</span>
        </span>
      ),
      value: 'fields'
    }
  ];

  useEffect(() => {
    // If the app data is being fetched, we want to wait until that fetch is done instead of using any potential app data in the react query cache
    // Otherwise, this effect will run with potential stale app data
    if (!app || isAppLoading) return;

    const initializeStores = async () => {
      await initializeFieldsStore({ app, objectKey });
    };

    try {
      void initializeStores();
    } catch (error) {
      presentToast({
        title: t('components.data_table.errors.table_not_found')
      });
    }
  }, [app, initializeFieldsStore, objectKey, presentToast, t, isAppLoading, reset, activeTab]);

  const handleChangeActiveTab = (value: TablesPageTab['value']) => {
    isChangingTab.current = true;
    const fieldsRoute = isUserObject ? ROUTES.ROLES_ID_FIELDS : ROUTES.TABLES_ID_FIELDS;
    const recordsRoute = isUserObject ? ROUTES.ROLES_ID : ROUTES.TABLES_ID;

    if (value === 'fields') {
      navigate(generatePath(fieldsRoute, { id: objectKey }));
    } else {
      navigate(generatePath(recordsRoute, { id: objectKey }));
    }
  };

  if (isAppLoading || !app) {
    return (
      <div
        data-testid="data-table-display-container"
        className="flex min-h-[300px] w-full items-center justify-center"
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <div>
        <TablesPageTopbar objectKey={objectKey} />
      </div>
      <div className="flex-1 overflow-auto">
        <Tabs
          defaultValue={activeTab}
          className="flex h-full flex-col"
          onValueChange={(value) => {
            setUpdatedActiveTab(value);
            handleChangeActiveTab(value as TablesPageTab['value']);
          }}
          data-testid="tables-page-tabs"
        >
          <div
            className="flex justify-between gap-2 px-6 py-3"
            key={`fields-tab-${totalRecords}-${fields.length}`}
          >
            <div data-testid="tables-tabs-list">
              <Tabs.List
                items={items}
                onMouseUp={() => {
                  if (!isChangingTab.current && activeTab === 'records') {
                    refetchPagesInViewport();
                  }
                }}
              />
            </div>
            {updatedActiveTab === 'records' ? <DataTableHeader /> : <FieldsHeader />}
          </div>
          <Tabs.Content className="flex-grow overflow-auto p-0" value="records">
            <DataTableDisplayInitialize objectKey={objectKey} app={app}>
              <DataTableDisplay />
            </DataTableDisplayInitialize>
          </Tabs.Content>
          <Tabs.Content className="p-0" value="fields">
            <FieldsList />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
}
