import DOMPurify from 'dompurify';

import { DurationEdit } from '@/components/data-table/display/fields/duration/DurationEdit';
import { type DurationField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';

export function DurationRender(props: FieldRenderProps<DurationField>) {
  const { isFloating, value, rowId, fieldId } = props;
  const sanitizedValue = DOMPurify.sanitize(value);

  if (isFloating) {
    return <DurationEdit {...props} />;
  }

  return (
    <div
      className="flex size-full flex-col p-2"
      data-testid={`duration-render-${rowId}-${fieldId}`}
    >
      <p className="size-full truncate" dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
    </div>
  );
}
