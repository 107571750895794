import { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';

export type PageEditorUiOptions = {
  shouldShowViewLabels: boolean;
  shouldCollapseSections: boolean;
  dataPreviewType: 'live' | 'sample';
};

type PageEditorUiOptionsContextState = {
  pageEditorUiOptions: PageEditorUiOptions;
  setPageEditorUiOptions: (options: Partial<PageEditorUiOptions>) => void;
};

const PageEditorUiOptionsContext = createContext<PageEditorUiOptionsContextState | null>(null);

export function PageEditorUiOptionsContextProvider({ children }: { children: React.ReactNode }) {
  const { sendMessageToLiveApp } = usePageEditorMessagingContext();

  const [pageEditorUiOptions, setPageEditorUiOptions] = useLocalStorage<PageEditorUiOptions>(
    'pageEditorUiOptions',
    {
      shouldShowViewLabels: false,
      shouldCollapseSections: false,
      dataPreviewType: 'live'
    }
  );

  const handleUpdatePageEditorUiOptions = useCallback(
    (options: Partial<PageEditorUiOptions>) => {
      const newUiOptions = { ...pageEditorUiOptions, ...options };

      setPageEditorUiOptions(newUiOptions);
      sendMessageToLiveApp({
        action: 'ui-options',
        uiOptions: newUiOptions
      });
    },
    [pageEditorUiOptions, setPageEditorUiOptions, sendMessageToLiveApp]
  );

  const contextValue = useMemo(
    () => ({ pageEditorUiOptions, setPageEditorUiOptions: handleUpdatePageEditorUiOptions }),
    [pageEditorUiOptions, handleUpdatePageEditorUiOptions]
  );

  return (
    <PageEditorUiOptionsContext.Provider value={contextValue}>
      {children}
    </PageEditorUiOptionsContext.Provider>
  );
}

export const usePageEditorUiOptionsContext = () => {
  const context = useContext(PageEditorUiOptionsContext);

  if (!context) {
    throw new Error(
      'usePageEditorUiOptionsContext must be used within an PageEditorUiOptionsContextProvider'
    );
  }

  return context;
};
