import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiLink as LinkIcon } from 'react-icons/hi2';
import {
  Button,
  Divider,
  Input,
  Label,
  Switch,
  Tabs,
  type TabsListItem
} from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import {
  getApplicationBasePathSegments,
  getCurrentPageSlug,
  getPageAncestorsUrl
} from '@/utils/application';
import { PAGE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { formatSlug } from '@/utils/formatters';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

export function PageSettingsGeneral() {
  const { data: allPages } = usePagesQuery();
  const { page, updatePage } = usePageEditorContext();
  const { parentSlug } = page;
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  const liveAppBasePath = `${import.meta.env.PUBLIC_LIVE_APP_URL}/${accountSlug}/${appSlug}`;
  const [t] = useTranslation();
  const [isSelectIconModalOpen, setIsSelectIconModalOpen] = useState(false);

  // Keep local copy of the updated slug so we can update it on blur
  const [localSlug, setLocalSlug] = useState(page.slug);
  const urlWithAncestors = `${liveAppBasePath}${getPageAncestorsUrl(page, allPages)}/`;
  const pageSlug = getCurrentPageSlug(page, allPages);

  const existingPageSlugs = allPages?.filter((p) => p.slug !== page.slug).map((p) => p.slug) ?? [];

  const defaultIconAlignment = 'left';
  const alignItems: TabsListItem[] = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  const handlePageUpdate = (pageUpdates: Partial<BuilderPage>) => {
    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates
    });
  };

  const handlePageMenuDisplay = (checked: boolean) => {
    handlePageUpdate({ pageMenuDisplay: checked });
  };

  const handleIgnoreEntrySceneMenu = (checked: boolean) => {
    handlePageUpdate({ ignoreEntrySceneMenu: !checked });
  };

  const handlePrint = (checked: boolean) => {
    handlePageUpdate({ print: checked });
  };

  const handleModal = (value: string) => {
    handlePageUpdate({ modal: value === 'modal' });
  };

  const handleModalPreventBackgroundClickClose = (checked: boolean) => {
    handlePageUpdate({
      modalPreventBackgroundClickClose: checked
    });
  };

  return (
    <>
      <Divider className="my-4" />
      <Label className="mb-2 block font-medium">{t('pages.settings.page_name')}</Label>
      <Input
        className="mb-4"
        value={page.name}
        maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
        onChange={(e) => {
          const newName = e.target.value;
          if (newName.length > PAGE_NAME_MAX_CHAR_LENGTH) return;

          handlePageUpdate({ name: newName });
        }}
      />
      <Label className="mb-2 block font-medium">{t('pages.settings.page_slug')}</Label>
      <Input
        value={localSlug}
        intent={existingPageSlugs.includes(localSlug) || !localSlug ? 'destructive' : 'default'}
        maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
        onChange={(e) => {
          setLocalSlug(formatSlug(e.target.value));
        }}
        onBlur={() => {
          handlePageUpdate({ slug: localSlug });
        }}
      />
      {existingPageSlugs.includes(localSlug) && (
        <p className="mt-1 text-xs text-destructive">
          {t('pages.settings.page_slug_already_exists')}
        </p>
      )}
      <div className="mt-2 flex items-center rounded-lg bg-emphasis p-2">
        <LinkIcon className="shrink-0" />
        <a
          href={`${urlWithAncestors}${pageSlug}`}
          target="_blank"
          className="ml-2"
          style={{ overflowWrap: 'anywhere' }}
          rel="noreferrer"
        >
          {urlWithAncestors}
          <strong className="font-medium">{pageSlug}</strong>
        </a>
      </div>
      <div className="mt-2 flex items-center gap-2">
        <Switch
          id="show-title"
          checked={page.isTitleVisible}
          onCheckedChange={(newValue) => {
            handlePageUpdate({ isTitleVisible: newValue });
          }}
        />
        <Label htmlFor="show-title">{t('pages.settings.show_title')}</Label>
      </div>

      <Divider className="my-4" />

      <BuilderAccordion>
        <BuilderAccordion.Item isDefaultOpen label={t('pages.settings.display')} className="mt-4">
          <Label>{t('pages.settings.display_as')}</Label>
          <Tabs
            defaultValue={page.modal ? 'modal' : 'fullpage'}
            onValueChange={handleModal}
            className="mt-2"
          >
            <Tabs.List
              items={[
                { value: 'fullpage', children: 'Full Page' },
                { value: 'modal', children: 'Modal' }
              ]}
              shouldDisableResponsive
            />
            <Tabs.Content value="modal">
              <Label className="mt-2 flex items-center gap-2">
                <Switch
                  checked={page.modalPreventBackgroundClickClose}
                  onCheckedChange={handleModalPreventBackgroundClickClose}
                />
                {t('pages.settings.modal_prevent_background_click_close')}
              </Label>
            </Tabs.Content>
          </Tabs>
          {!parentSlug && (
            <div className="mt-4 flex-col items-center gap-2">
              <Label className="mb-2 block font-medium">{t('keywords.icon')}</Label>
              <div className="flex">
                {page.icon ? (
                  <>
                    <KnackFontAwesomeIcon
                      iconName={getNormalizedIconName(page.icon?.icon)}
                      size="xl"
                      asButton
                      onClick={() => setIsSelectIconModalOpen(true)}
                    />
                    <Button
                      intent="secondary"
                      className="ml-2"
                      aria-label={t('components.icons.remove_icon')}
                      onClick={() => {
                        handlePageUpdate({
                          icon: null
                        });
                      }}
                    >
                      {t('components.icons.remove_icon')}
                    </Button>
                  </>
                ) : (
                  <Button
                    intent="secondary"
                    aria-label={t('components.icons.select_icon')}
                    onClick={() => setIsSelectIconModalOpen(true)}
                  >
                    {t('components.icons.select_icon')}
                  </Button>
                )}
              </div>
              <div className="mt-4 flex-col items-center gap-2">
                <Label className="mb-2 block font-medium">
                  {t('components.icons.icon_position')}
                </Label>
                <Tabs
                  defaultValue={page.icon?.align || defaultIconAlignment}
                  onValueChange={(value) =>
                    handlePageUpdate({
                      icon: {
                        align: value as 'left' | 'right',
                        icon: page.icon ? page.icon.icon : defaultIconAlignment
                      }
                    })
                  }
                >
                  <Tabs.List items={alignItems} shouldDisableResponsive />
                </Tabs>
              </div>
              {isSelectIconModalOpen && (
                <SelectIconModal
                  preSelectedIcon={page.icon?.icon}
                  onClose={() => setIsSelectIconModalOpen(false)}
                  onSubmit={(newSelectedIconName) => {
                    if (newSelectedIconName) {
                      handlePageUpdate({
                        icon: {
                          align: page.icon?.align ?? defaultIconAlignment,
                          icon: newSelectedIconName
                        }
                      });
                    }
                    setIsSelectIconModalOpen(false);
                  }}
                />
              )}
            </div>
          )}
        </BuilderAccordion.Item>
      </BuilderAccordion>

      <Divider className="my-4" />

      <BuilderAccordion>
        <BuilderAccordion.Item isDefaultOpen label={t('pages.settings.options')} className="mt-4">
          <Label className="mb-2 flex items-center gap-2">
            <Switch checked={page.print} onCheckedChange={handlePrint} />
            {t('pages.settings.print')}
          </Label>
          {!parentSlug && (
            <>
              <Label className="mb-2 flex items-center gap-2">
                <Switch
                  checked={!page.ignoreEntrySceneMenu}
                  onCheckedChange={handleIgnoreEntrySceneMenu}
                />
                {t('pages.settings.include_entry_scene_menu')}
              </Label>
              <Label className="mb-2 flex items-center gap-2">
                <Switch checked={page.pageMenuDisplay} onCheckedChange={handlePageMenuDisplay} />
                {t('pages.settings.page_menu_display')}
              </Label>
            </>
          )}
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </>
  );
}
