import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Divider, Label, Switch } from '@knack/asterisk-react';
import { t } from 'i18next';
import { z } from 'zod';

import {
  DATE_FORMAT_OPTIONS,
  DEFAULT_DATE_TYPE_OPTIONS,
  DEFAULT_TIME_TYPE_OPTIONS,
  TIME_FORMAT_OPTIONS,
  type DateTimeField
} from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import { dateRegex, timeRegex } from '@/components/data-table/helpers/zod';
import { DateFormatSettings } from '@/components/field-settings/date-time/components/DateFormatSettings';
import { IncludeDateTimeSwitch } from '@/components/field-settings/date-time/components/IncludeDateTimeSwitch';
import { TimeFormatSettings } from '@/components/field-settings/date-time/components/TimeFormatSettings';

type DateTimeFormSettingsProps = {
  field: DateTimeField;
};

export const dateTimeSchema = z.object({
  format: z.object({
    date_format: z.enum(DATE_FORMAT_OPTIONS).default('mm/dd/yyyy'),
    time_format: z.enum(TIME_FORMAT_OPTIONS).default('HH:MM am'),
    calendar: z.boolean().optional(),
    default_date: z.string().optional(),
    default_time: z.string().optional(),
    default_type: z.enum(DEFAULT_DATE_TYPE_OPTIONS).optional(),
    time_type: z.enum(DEFAULT_TIME_TYPE_OPTIONS).optional()
  })
});

export function DateTimeFormSettings({ field }: DateTimeFormSettingsProps) {
  const { watch, register, setValue } = useFormContext();

  const defaultDate = watch('format.default_date');
  const defaultTime = watch('format.default_time');

  const timeFormat: (typeof TIME_FORMAT_OPTIONS)[number] =
    watch('format.time_format') || 'HH:MM am';
  const defaultTimeFormatRegex = timeFormat ? timeRegex[timeFormat] : timeRegex['HH:MM am'];
  const defaultTimeIsInvalid = !!defaultTime && !defaultTime?.match(defaultTimeFormatRegex);

  const dateFormat: (typeof DATE_FORMAT_OPTIONS)[number] =
    watch('format.date_format') || 'mm/dd/yyyy';
  const defaultDateFormatRegex = dateFormat ? dateRegex[dateFormat] : dateRegex['mm/dd/yyyy'];
  const defaultDateIsInvalid = !!defaultDate && !defaultDate?.match(defaultDateFormatRegex);

  const isSpecificDefaultDateOptionSelected = watch('format.default_type') === 'date';
  const isSpecificDefaultTimeOptionSelected = watch('format.time_type') === 'time';

  const isIgnoreDateSelected = dateFormat === 'Ignore Date';
  const isIgnoreTimeSelected = timeFormat === 'Ignore Time';

  useEffect(() => {
    // if  date format is Ignore Date, 'format.calendar' needs to be set to false to avoid invalid numeric representation
    // in the live app UI
    if (isIgnoreDateSelected) {
      setValue('format.calendar', false);
    }
  }, [isIgnoreDateSelected, setValue, watch]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <IncludeDateTimeSwitch field={field} type="date" />

        {!isIgnoreDateSelected && (
          <div className="flex flex-col gap-2">
            <DateFormatSettings field={field} />

            {isSpecificDefaultDateOptionSelected && (
              <div>
                <input
                  placeholder={t(
                    `components.data_table.attributes.field_settings.date_time.date_format_options.${dateFormat}`
                  )}
                  className={cn(
                    'w-[160px] rounded-lg border border-default bg-base ring-0 focus:border-current focus:ring-0',
                    {
                      'border-0 ring-2 ring-destructive focus:border-0 focus:ring-2 focus:ring-destructive':
                        defaultDateIsInvalid
                    }
                  )}
                  data-testid="default-date-form-settings-input"
                  defaultValue={field?.format?.default_date}
                  {...register('format.default_date')}
                />
                {defaultDateIsInvalid && (
                  <p
                    className="py-1 text-destructive"
                    data-testid="default-date-form-settings-error"
                  >
                    {t('components.data_table.errors.no_valid_field', { field: 'date' })}
                  </p>
                )}
              </div>
            )}

            <div className="mt-2 flex items-center">
              <Controller
                name="format.calendar"
                defaultValue={field?.format?.calendar || false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    id="enable-repeat-option-and-end-date-switch"
                    className="mr-2"
                    data-testid="enable-repeat-option-and-end-date-switch"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                )}
              />
              <Label htmlFor="enable-repeat-option-and-end-date-switch" className="font-medium">
                {t(
                  'components.data_table.attributes.field_settings.date_time.include_repeat_and_end_date'
                )}
              </Label>
            </div>
          </div>
        )}
      </div>

      <Divider className="my-2" />

      <div className="flex flex-col gap-4">
        <IncludeDateTimeSwitch field={field} type="time" />

        {!isIgnoreTimeSelected && (
          <div className="flex flex-col gap-2">
            <TimeFormatSettings field={field} />

            {isSpecificDefaultTimeOptionSelected && (
              <div>
                <input
                  placeholder={t(
                    `components.data_table.attributes.field_settings.date_time.time_format_options.${timeFormat}`,
                    { nsSeparator: false }
                  )}
                  className={cn(
                    'w-[160px] rounded-lg border border-default bg-base ring-0 focus:border-current focus:ring-0',
                    {
                      'border-0 ring-2 ring-destructive focus:border-0 focus:ring-2 focus:ring-destructive':
                        defaultTimeIsInvalid
                    }
                  )}
                  defaultValue={field?.format?.default_time}
                  data-testid="default-time-form-settings-input"
                  {...register('format.default_time')}
                />
                {defaultTimeIsInvalid && (
                  <p
                    className="py-1 text-destructive"
                    data-testid="default-time-form-settings-error"
                  >
                    {t('components.data_table.errors.no_valid_field', { field: 'time' })}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
